







































































import {
  SfList,
  SfLink,
  SfFooter,
  SubscribeForm
} from '~/components';

import { defineComponent, computed } from '@nuxtjs/composition-api';
import { useConfigStore } from "~/stores/config";

export default defineComponent({
  name: 'AppFooter',
  components: {
    SubscribeForm,
    SfFooter,
    SfList,
    SfLink,
  },
  setup() {
    const { storeConfig } = useConfigStore();
    const websiteCode = computed(() => storeConfig.website_code);

    const links = {
      worldOfTogas: [
        { title: 'About Us', url: '/about-us' },
        { title: 'Corporate responsibility', url: '/csr' },
        { title: 'Find your location', url: '/store-locator' },
        { title: 'Contact us', url: '/contact-us' },
      ],
      customerCare: [
        { title: 'Shipping and returns', url: '/customer-care/shipment-returns' },
        { title: 'Privacy policy', url: '/customer-care/privacy-policy' },
        { title: 'Impressum', url: '/customer-care/impressum', onlyForWebsites: ['togas_at_website'] },
        { title: 'Terms and conditions', url: '/customer-care/terms-conditions' },
        { title: 'Loyalty Program', url: '/customer-care/loyalty-program' },
        { title: 'Blog', url: '/blog' },
      ],
      services: [
        { title: 'Gift Packing', url: '/customer-care/gift-packing' },
        { title: 'Promotions', url: '/promo' },
      ],
      businessInquiries: [],
    };

    return {
      links,
      websiteCode
    };
  },
});
